:root {
  --ci-ta11y-open-duration: 0.5s;
}

body {
  font-size: 18px;
  background: black;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  color: white;
}

a {
  color: white;
}

.ci-tota11y-accordion > li > button {
  padding: 2rem 1.5rem;
  display: block;
  color: #151618;
  position: relative;
  cursor: pointer;
  outline: transparent;
  text-decoration: none;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: '100%';
  border-radius: 1rem;
  padding: '0 20px'
}

.ci-tota11y-accordion > li {
  max-width: 800px;
  box-sizing: border-box;
  border-radius: 1rem;
  background: #f6f6f6;
  box-shadow: 0px 0px 0px 0px #333;
  display: grid;
  grid-template-rows: min-content 0fr;
  transition: box-shadow 0.3s 0s ease,
    grid-template-rows var(--ci-ta11y-open-duration);
}

.ci-tota11y-accordion > li:focus-within {
  box-shadow: 0 0 0 4px #999, 0 0 0 6px #2cdce6;
}

.ci-tota11y-accordion > li.ci-expanded > button:before {
  transform: rotate(180deg);
}

.ci-tota11y-accordion > li > button:before {
  /* content: "";
  opacity: 0.6;
  background-image: url("https://assets.codepen.io/7212043/rounded-arrow.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1opx;
  right: 1.5rem;
  transform: rotate(270deg);
  transform-origin: 1rem 50%;
  transition: 0.25s transform ease;
  filter: invert(8%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(40%) contrast(100%); */
}

.ci-tota11y-accordion > li .ci-content {
  border-top: none;
  margin: 0 1rem;
  padding: 0 0.5rem;
  color: #151618;
  visibility: hidden;
  display: grid;
  overflow: hidden;
  transition: margin var(--ci-ta11y-open-duration) linear,
    padding var(--ci-ta11y-open-duration) linear,
    max-height var(--ci-ta11y-open-duration) linear;
  max-height: 0;
  min-width: 500px;
}

.ci-tota11y-accordion > li .ci-content::-webkit-scrollbar {
  width: 14px;
}

.ci-tota11y-accordion > li .ci-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: #f5f5f5;
}

.ci-tota11y-accordion > li .ci-content::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

@keyframes ci-show-scroll {
  0% {
    overflow-y: hidden;
  }
  0.2% {
    overflow-y: auto;
  }
  to {
    overflow-y: auto;
  }
}

.ci-tota11y-accordion > li .ci-content:focus {
  outline: none;
}

.ci-tota11y-accordion > li.ci-expanded {
  grid-template-rows: min-content 1fr;
}

.ci-tota11y-accordion > li.ci-expanded .ci-content {
  visibility: visible;
  margin: 0rem 1rem 2rem 1rem;
  padding: 0.5rem;
  max-height: 350px;
  animation-duration: calc(2000 * var(--ci-ta11y-open-duration));
  animation-name: ci-show-scroll;
  overflow-y: auto;
}

@media (prefers-reduced-motion) {
  :root {
    --ci-ta11y-open-duration: 0s;
  }
  .ci-tota11y-accordion > li.ci-expanded .ci-content {
    overflow-y: auto;
  }
  .ci-tota11y-accordion > li > button:before {
    transition: none;
  }
}
