:root {
  --ta11y-open-duration: 0.5s;
}

body {
  font-size: 18px;
  background: black;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  color: white;
  /* padding: 30px; */
}

a {
  color: white;
}

.tota11y-accordion > li > button {
  padding: 2rem 1.5rem;
  display: block;
  color: #151618;
  position: relative;
  cursor: pointer;
  outline: transparent;
  text-decoration: none;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
}

.tota11y-accordion > li {
  max-width: 800px;
  box-sizing: border-box;
  margin-top: 10px;
  border-radius: 1rem;
  /* background: #151618; */
  background: #fff;
  box-shadow: 0px 0px 0px 0px #333;
  display: grid;
  grid-template-rows: min-content 0fr;
  transition: box-shadow 0.3s 0s ease,
    grid-template-rows var(--ta11y-open-duration);
}

.tota11y-accordion > li:focus-within {
  box-shadow: 0 0 0 4px #000, 0 0 0 6px #2cdce6;
}

.tota11y-accordion > li.expanded > button:before {
  transform: rotate(180deg);
}

.tota11y-accordion > li > button:before {
  content: "";
  opacity: 0.6;
  background-image: url("https://assets.codepen.io/7212043/rounded-arrow.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.8rem;
  right: 1.5rem;
  transform: rotate(0deg);
  transform-origin: 1rem 50%;
  transition: 0.25s transform ease;
  filter: invert(8%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(40%) contrast(100%);
}




.tota11y-accordion > li .content {
  border-top: none;
  margin: 0 1rem;
  padding: 0 0.5rem;
  border-top: none;
  /* color: #a9abb3; */
  color: #151618;
  visibility: hidden;
  display: grid;
  overflow: hidden;
  transition: margin var(--ta11y-open-duration) linear,
    padding var(--ta11y-open-duration) linear,
    max-height var(--ta11y-open-duration) linear;
  max-height: 0;
}

.tota11y-accordion > li .content::-webkit-scrollbar {
  width: 14px;
}

.tota11y-accordion > li .content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: #f5f5f5;
}

.tota11y-accordion > li .content::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

@keyframes show-scroll {
  0% {
    overflow-y: hidden;
  }
  0.2% {
    overflow-y: auto;
  }
  to {
    overflow-y: auto;
  }
}

.tota11y-accordion > li .content:focus {
  outline: none;
}

.tota11y-accordion > li.expanded {
  grid-template-rows: min-content 1fr;
}

.tota11y-accordion > li.expanded .content {
  visibility: visible;
  margin: 0.5rem 1rem 2rem 1rem;
  padding: 0.5rem;
  max-height: 200px;
  animation-duration: calc(2000 * var(--ta11y-open-duration));
  animation-name: show-scroll;
  overflow-y: auto;
}

@media (prefers-reduced-motion) {
  :root {
    --ta11y-open-duration: 0s;
  }
  .tota11y-accordion > li.expanded .content {
    overflow-y: auto;
  }
  .tota11y-accordion > li > button:before {
    transition: none;
  }
}
